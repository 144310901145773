<script setup>
import { useUserStore } from '~/stores/user.store';
import MobileAccount from './partials/account.vue';

const userStore = useUserStore();
const user = ref(undefined);
const isLoading = ref(true);
const logout = async () => {
    await userStore.logout();
}
onMounted(async () => {
    user.value = userStore.userGetter();
    if (!user.value) {
        await userStore.getUserData();
        user.value = userStore.userGetter();
    }
    isLoading.value = false;
})
</script>

<template>
    <NuxtLayout name="mobile-base">
        <template #mobile-header>
            <MobileHeader title="More">
                <template #header-left>
                    <mobile-back-button></mobile-back-button>
                </template>
            </MobileHeader>
        </template>
        <div v-if="isLoading" class="relative h-screen">
            <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <span class="loader"></span>
            </div>
        </div>
        <div v-else class="flex flex-col item-center px-4 mt-4">
            <mobile-account :user="user" :logout="logout"></mobile-account>

            <div class="flex flex-col items-center bg-white p-3 rounded-xl shadow-sm mb-3">
                <mobile-language></mobile-language>
                <mobile-currency></mobile-currency>
                <!-- <div v-if="user" class="w-full *:border-b *:py-3  *:last-of-type:border-b-0">
                <a href="notification-settings.html" class="block">
                    <div class="flex items-center justify-between">
                        <div class="flex items-center gap-4">
                            <i class="fa-solid fa-bell fa-fw text-indigo-900"></i>
                            <span class="text-black text-xl">{{$t('client.notification_settings')}}</span>
                        </div>
                    </div>
                </a>
            </div> -->
            </div>

            <!-- <div v-if="user" class="flex flex-col items-center bg-white p-3 rounded-xl shadow-sm mb-3">
            <div class="w-full *:border-b *:py-3  *:last-of-type:border-b-0">
                <a href="../more/my-listings.html" class="block">
                    <div class="flex items-center justify-between">
                        <div class="flex items-center gap-4">
                            <i class="fa-solid fa-bullhorn fa-fw text-indigo-900"></i>
                            <span class="text-black text-xl">{{ $t('client.my_listing') }}</span>
                        </div>
                    </div>
                </a>
            </div>
            <div class="w-full *:border-b *:py-3  *:last-of-type:border-b-0">
                <a href="../more/reels.html" class="block">
                    <div class="flex items-center justify-between">
                        <div class="flex items-center gap-4">
                            <i class="fa-solid fa-circle-play fa-fw text-indigo-900"></i>
                            <span class="text-black text-xl">{{ $t('client.reels') }}</span>
                        </div>
                    </div>
                </a>
            </div>
            <div class="w-full *:border-b *:py-3  *:last-of-type:border-b-0">
                <a href="../more/favorites.html" class="block">
                    <div class="flex items-center justify-between">
                        <div class="flex items-center gap-4">
                            <i class="fa-solid fa-heart fa-fw text-indigo-900"></i>
                            <span class="text-black text-xl">{{ $t('client.favorite') }}</span>
                        </div>
                    </div>
                </a>
            </div>
            <div class="w-full *:border-b *:py-3  *:last-of-type:border-b-0">
                <a href="../more/saved-search.html" class="block">
                    <div class="flex items-center justify-between">
                        <div class="flex items-center gap-4">
                            <i class="fa-solid fa-star fa-fw text-indigo-900"></i>
                            <span class="text-black text-xl">{{ $t('client.saved_searches') }}</span>
                        </div>
                    </div>
                </a>
            </div>
        </div> -->
            <div v-if="user" class="flex flex-col items-center bg-white p-3 rounded-xl shadow-sm mb-3">
                <div class="w-full *:border-b *:py-3  *:last-of-type:border-b-0">
                    <NuxtLink :to="localePath({ name: 'account.offers.index' })" class="block">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center gap-4">
                                <i class="fa-solid fa-tags fa-fw text-indigo-900"></i>
                                <span class="text-black text-xl">{{ $t('client.offers') }}</span>
                            </div>
                        </div>
                    </NuxtLink>
                </div>
                <div class="w-full *:border-b *:py-3  *:last-of-type:border-b-0">
                    <NuxtLink :to="localePath({ name: 'listings.add' })" class="block">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center gap-4">
                                <i class="fa-solid fa-plus-circle fa-fw text-indigo-900"></i>
                                <span class="text-black text-xl">{{ $t('client.add_listing') }}</span>
                            </div>
                        </div>
                    </NuxtLink>
                </div>
                <div class="w-full *:border-b *:py-3  *:last-of-type:border-b-0">
                    <NuxtLink :to="localePath({ name: 'account.customers' })" class="block">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center gap-4">
                                <i class="fa-solid fa-user-tie fa-fw text-indigo-900"></i>
                                <span class="text-black text-xl">{{ $t('client.customers') }}</span>
                            </div>
                        </div>
                    </NuxtLink>
                </div>
            </div>
            <div class="flex flex-col items-center bg-white p-3 rounded-xl shadow-sm mb-3">
                <div class="w-full *:border-b *:py-3  *:last-of-type:border-b-0">
                    <NuxtLink :to="localePath({ name: 'slug', params: { slug: 'life-in-turkey' } })" class="block">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center gap-4">
                                <i class="fa-regular fa-newspaper fa-fw text-indigo-900"></i>
                                <span class="text-black text-xl">{{ $t('client.blog') }}</span>
                            </div>
                        </div>
                    </NuxtLink>
                </div>
                <div class="w-full *:border-b *:py-3  *:last-of-type:border-b-0">
                    <NuxtLink :to="localePath({ name: 'guides.show', params: { location: 'turkey' } })" class="block">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center gap-4">
                                <i class="fa-solid fa-flag fa-fw text-indigo-900"></i>
                                <span class="text-black text-xl">{{ $t('client.area_guides') }}</span>
                            </div>
                        </div>
                    </NuxtLink>
                </div>
                <div class="w-full *:border-b *:py-3  *:last-of-type:border-b-0">
                    <NuxtLink :to="localePath({ name: 'teammate.index' })" class="block">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center gap-4">
                                <i class="fa-solid fa-users-line fa-fw text-indigo-900"></i>
                                <span class="text-black text-xl">{{ $t('client.our_team') }}</span>
                            </div>
                        </div>
                    </NuxtLink>
                </div>
                <div class="w-full *:border-b *:py-3  *:last-of-type:border-b-0">
                    <NuxtLink :to="localePath({ name: 'contact_us' })" class="block">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center gap-4">
                                <i class="fa-solid fa-phone fa-fw text-indigo-900"></i>
                                <span class="text-black text-xl">{{ $t('client.contact_us') }}</span>
                            </div>
                        </div>
                    </NuxtLink>
                </div>
            </div>
        </div>
    </NuxtLayout>
</template>